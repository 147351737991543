import React from 'react';
import Layout from '../components/layout';
import Button from '../components/layout/button';

const FailedPage = () => {
  return (
    <Layout
      seo={{
        title: 'Sikertelen',
        description:
          'Sikerélményt viszünk a fiatalok életébe szociális helyzettől függetlenül.'
      }}
    >
      <div className="bg-bgfirst ">
        <div className="conatiner flex justify-center text-center">
          <div className="max-w-lg mt-24 mb-20 p-5">
            <img
              alt="Succes"
              src="/falid.jpg"
              width=""
              className="mx-auto mt-10 mb-10 rounded-xl"
            />{' '}
            <h2 className="mb-10 mt-10 uppercase">Sikertelen adományozás!</h2>
            <p className="mb-5 mt-5">
              Hiba történt a tranzakció során, az összeg nem került levonásra.
              Az újbóli próbálkozáshoz kattints a gombra és a küldés előtt
              győződj meg róla, hogy minden adatod helyesen adtad meg.
            </p>
            <Button to="/tamogatas" variant="primary" className="mb-10">
              Megpróbálom újra
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FailedPage;
